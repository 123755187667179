<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Health Reimbursement (HRA)</h1>

      <p>
        An HRA may be funded only by employer contributions. Money in the
        account may be used to reimburse employees for deductible payments and
        other out-of-pocket health costs (including spouse and dependents).
        Funds in a Health Reimbursement Account may roll over to the next year
        according to the policies of the employer.
      </p>

      <h2>What Are the Tax Advantages of an HRA?</h2>
      <p>
        If an HRA is designed and operated in compliance with IRS guidance,
        employees will not be taxed on the value of their HRA coverage or on
        reimbursements that they receive from the HRA, under Code §105 and §106,
        even though unused amounts may carry forward into future years for
        reimbursement of medical expenses. The employer will, subject to certain
        conditions and limitations, be entitled to a deduction for
        reimbursements made under the HRA.
      </p>
    </div>
    <InfoDropdown title="Example: Health Reimbursement Account" :headerLvl="2">
      <p>
        Here is an example of how an HRA might work in conjunction with a common
        high-deductible health coverage (HDHC) arrangement.
      </p>
      <p>
        An employer has an insured plan with high-deductible health coverage
        (HDHC), but with first-dollar coverage for certain preventive services
        such as flu shots, mammograms, well-child checkups, and annual physical
        exams. The employer pays 50% of the HDHC premium, and employees pay the
        other 50% with pre-tax salary reductions under a cafeteria plan.
      </p>
      <p>
        Employees who participate in the HDHC also participate in an
        employer-funded HRA with a $1,000 annual limit that can be used to pay
        for out-of-pocket medical expenses (e.g., co-pays, deductibles, and
        medical expenses that are not covered by the HDHC). Expenses submitted
        to the HRA may qualify for negotiated provider discounts like the
        discounts available under the HDHC. Unused HRA amounts can be carried
        over from year to year for reimbursement of medical expenses. The HRA is
        not part of the cafeteria plan.
      </p>
      <p>
        The health plan in the example above is a hybrid arrangement combining
        features of traditional health care with consumer-driven health care
        (CDHC) features. It is designed to heighten employee awareness of health
        care costs and reduce unwise expenditures by having employees bear the
        cost of their medical care (i.e., they will have to dip into their HRAs)
        up to the HDHC deductible amount. Proponents of a consumer-driven
        approach claim that it will reduce overall health costs by making
        employees better health care consumers. Others say that it penalizes
        individuals who have greater health care needs.
      </p>
      <p>
        An HRA can be coupled with HDHC as in the above example, or it can be
        offered on a stand-alone basis. When a stand-alone HRA is offered, an
        employee would not be required to participate in HDHC in order to be
        eligible to participate in the HRA.
      </p>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown: InfoDropdown
  }
};
</script>
